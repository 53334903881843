<template>
  <v-row>
    <v-col cols="8">
      <v-file-input
        accept="image/png, image/jpeg, image/bmp, image/jpg"
        placeholder="Change profile picture"
        prepend-icon="mdi-camera"
        v-model="uploadPhoto"
        label="Picture"
        outlined
        rounded
        persistent-hint
        hint="Set a picture with your face displayed clearly!"
      ></v-file-input>
    </v-col>
    <v-col cols="4">
      <v-btn
        :color="buttonColor"
        x-large
        rounded
        :loading="photoButtonLoading"
        @click="updatePicture"
      >
        {{ updateText }}
      </v-btn>
    </v-col>
    <v-snackbar color="success" class="mb-10" v-model="success" :timeout="2000">
      Updated successfully
    </v-snackbar>
    <v-snackbar color="error" class="mb-10" v-model="failure" :timeout="2000">
      Something went wrong. Try again later
    </v-snackbar>
  </v-row>
</template>

<script>
import { db, storage } from "@/plugins/firebase";
import store from "@/store";

export default {
  fiery: true,
  data() {
    return {
      user: this.$fiery(
        db.collection("Attendance").doc(store.state.user.Login)
      ),
      uploadPhoto: null,
      success: false,
      failure: false,
      photoButtonLoading: false
    };
  },
  computed: {
    buttonColor() {
      if (this.user.photo && !this.uploadPhoto) {
        return "error";
      } else {
        return "success";
      }
    },
    updateText() {
      if (this.user.photo && !this.uploadPhoto) {
        return "Remove";
      } else if (!this.user.photo) {
        return "Set";
      } else {
        return "Update";
      }
    }
  },
  methods: {
    updatePicture() {
      if (this.uploadPhoto) {
        this.photoButtonLoading = true;
        const path = "members/" + store.state.user.Login;
        storage
          .child(path)
          .put(this.uploadPhoto)
          .then(snapshot => {
            snapshot.ref
              .getDownloadURL()
              .then(downloadURL => {
                this.user.photo = downloadURL;
                this.$fiery
                  .update(this.user)
                  .then(() => {
                    this.uploadPhoto = null;
                    this.success = true;
                    this.photoButtonLoading = false;
                  })
                  .catch(() => {
                    this.photoButtonLoading = false;
                    this.failure = true;
                  });
              })
              .catch(() => {
                this.photoButtonLoading = false;
                this.failure = true;
              });
          })
          .catch(() => {
            this.photoButtonLoading = false;
            this.failure = true;
          });
      } else {
        this.user.photo = null;
        this.$fiery
          .update(this.user)
          .then(() => {
            this.success = true;
            this.photoButtonLoading = false;
          })
          .catch(() => {
            this.photoButtonLoading = false;
            this.failure = true;
          });
      }
    }
  }
};
</script>
