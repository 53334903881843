<template>
  <v-container class="align-content-center">
    <v-layout row wrap justify-center>
      <v-flex xs11 md6 my-4>
        <v-card v-if="user">
          <v-container>
            <!-- Title -->
            <v-row>
              <v-col class="justify-center">
                <div class="display-1 text-center">Your Profile</div>
              </v-col>
            </v-row>

            <!-- Profile Picture -->
            <v-row>
              <v-col class="d-inline-flex justify-center">
                <Avatar :user="user"></Avatar>
              </v-col>
            </v-row>

            <!-- Picture Update -->
            <UpdatePicture />
          </v-container>
        </v-card>

        <v-skeleton-loader
          v-else
          class="mx-auto"
          type="card"
        ></v-skeleton-loader>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { db } from "@/plugins/firebase";
import store from "@/store";
import Avatar from "@/components/Profile/Avatar";
import UpdatePicture from "@/components/Profile/UpdatePicture";

export default {
  fiery: true,
  components: { Avatar, UpdatePicture },
  data() {
    return {
      user: this.$fiery(
        db.collection("Attendance").doc(store.state.user.Login)
      ),
    };
  },
};
</script>
